<template>
	<div>
		<base-data-table
			:title="$t('packages')"
			:headers="headers"
			:actions="actions"
			:provider="$packages"
			:fetch-function="getPackagesAdmin"
			translate
			@view="onViewPackage"
			@delete="onDeletePackage"
			@click:add="onInitPackage"
			@update="onUpdatePackage"
			@changeStatus="onChangeStatus"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{ $packages.perPage * ($packages.currentPage - 1) + index + 1 }}
			</template>

			<!-- STATUS -->
			<template v-slot:[`item.status`]="{ item }">
				<div
					:class="item.status == '1' ? 'tw-text-AA' : 'tw-text-72'"
					class="tw-capitalize tw-text-sm tw-font-semibold"
				>
					{{ item.status == '1' ? 'Active' : 'Inactive' }}
				</div>
			</template>

			<!-- DATE -->
			<template v-slot:[`item.activatedAt`]="{ item }">
				<div>
					{{
						item.activatedAt
							? $m(item.activatedAt).format('Do MMM YYYY')
							: 'Inactive'
					}}
				</div>
			</template>
		</base-data-table>

		<v-dialog
			max-width="1198"
			v-model="packageForm.dialog"
			v-if="packageForm.dialog"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<PackageForm
				v-if="packageForm.dialog"
				:error="error"
				:loading="loading"
				:onUpdate="onUpdate"
				:package="packageForm.data"
				:permissions="$permissions.supplier"
				:on-view.sync="onView"
				@onClose="onResetUserForm"
				@update="handleUpdatePackage"
				@create="handleCreatePackage"
			>
			</PackageForm>
		</v-dialog>

		<v-dialog
			content-class="tw-rounded-3xl tw-bg-white"
			persistent
			max-width="676"
			v-model="confirmStatusDialog"
		>
			<v-card elevation="0" class="tw-p-0 tw-mx-0">
				<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
					<h1 class="tw-text-2xl tw-font-semibold">
						<template v-if="+onChangeStatusData.status"
							>{{ $t('deactive-package') }}</template
						>
						<template v-else>{{ $t('active-package') }}</template>
					</h1>
					<!-- <div
						class="tw-text-black tw-text-opacity-25 tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center"
					>
						Are you sure about changing this user status because once you
						continue this action can't be undone.
					</div> -->
					<v-card-actions class="tw-flex tw-space-s-4">
						<v-btn
							depressed
							color="#DF9999"
							outlined
							@click="confirmStatusDialog = false"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							><span class="tw-capitalize tw-text-lg tw-font-medium">{{
								$t('actions.cancel')
							}}</span></v-btn
						>
						<v-btn
							depressed
							color="#DF9999"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							@click="handleChangePackageStatus"
							dark
							:loading="loading"
						>
							<span class="tw-capitalize tw-text-lg tw-font-medium">{{
								$t('actions.confirm')
							}}</span></v-btn
						>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>

		<DialogDeleteConfirmation
			v-model="confirmDialog"
			:id="onDeleteId"
			:title="$t('package')"
			:delete-function="deletePackage"
		/>
	</div>
</template>

<script>
import { pick } from 'lodash'
import { mapGetters, mapActions } from 'vuex'

import PackageForm from '@/components/forms/PackageForm.vue'
import DialogDeleteConfirmation from '../../components/dialogs/DialogDeleteConfirmation.vue'

export default {
	name: 'supplierUsers',
	components: {
		PackageForm,
		DialogDeleteConfirmation,
	},
	data() {
		return {
			packageForm: {
				dialog: false,
				data: null,
			},
			error: {},
			userData: {},
			onView: false,
			loading: false,
			onUpdate: false,

			confirmDialog: false,
			onDeleteId: null,
			onDeactiveId: null,
			onChangeStatusData: {},
			confirmStatusDialog: false,
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('users', ['$users']),
		...mapGetters('packages', ['$packages']),
		...mapGetters('permission', ['$permissions']),
		actions() {
			return [
				{
					text: this.$t('view-details'),
					event: 'view',
					icon: 'mdi-eye-outline',
				},
				{
					text: this.$t('actions.editDetails'),
					event: 'update',
					icon: 'mdi-pencil-outline',
				},
				{
					text: this.$t('status.deactive'),
					event: 'changeStatus',
					icon: 'mdi-minus-box-outline',
				},
				{
					text: this.$t('actions.delete'),
					event: 'delete',
					icon: 'mdi-delete-outline',
				},
			]
		},
		headers() {
			return [
				{ text: 'headers.ns', value: 'serial' },
				{
					text: 'headers.title',
					value: 'title',
					class: 'tw-whitespace-nowrap',
				},
				{
					text: 'headers.price',
					value: 'price',
					class: 'tw-whitespace-nowrap',
					computed: (v) => `SAR ${v.price}`,
				},
				{ text: 'headers.description', value: 'description', isGrow: true },
				{
					text: 'headers.status',
					value: 'status',
				},
				{ text: 'headers.actions', value: 'actions' },
			]
		},
	},
	methods: {
		...mapActions('users', [
			'fetchUsers',
			'deleteUser',
			'createUser',
			'updateUser',
			'changeStatusOfUser',
		]),
		...mapActions('permission', ['getPermissions']),
		...mapActions('packages', [
			'deletePackage',
			'updatePackage',
			'createPackage',
			'getPackagesAdmin',
		]),
		onInitPackage() {
			this.packageForm.dialog = true
		},
		onUpdatePackage(user) {
			console.log(user)
			this.packageForm.data = user
			this.packageForm.dialog = true
			this.onUpdate = true
		},
		onViewPackage(data) {
			console.log(data)
			this.packageForm.data = data
			this.packageForm.dialog = true
			this.onView = true
		},
		async handleCreatePackage(data) {
			this.loading = true
			let [err, res] = await this.createPackage({ ...data, status: 1 })
			if (err) {
				console.log(err)
				if (err.statusCode == 422) {
					this.error = err.errors
					this.$toast.error(
						this.$t('error-creating', [this.$t('package')])
					)
				}
				this.loading = false
			} else {
				this.$toast.success(this.$t('success-create', [this.$t('package')]))
				this.onResetUserForm()
				console.log(res)
				this.loading = false
			}
		},
		async handleUpdatePackage(data) {
			console.log(data)
			this.loading = true

			let [err, res] = await this.updatePackage(data)
			if (err) {
				console.log(err)
				if (err.statusCode == 422) {
					this.error = err.errors
					this.$toast.error(
						this.$t('error-updating', [this.$t('package')])
					)
				}
				this.loading = false
			} else {
				console.log(res)
				this.$toast.success(this.$t('success-update', [this.$t('package')]))
				this.onResetUserForm()
				this.loading = false
			}
		},
		onDeletePackage(data) {
			this.confirmDialog = true
			this.onDeleteId = data.id
			console.log(this.onDeleteId)
		},
		onChangeStatus(data) {
			this.confirmStatusDialog = true
			this.onChangeStatusData = data
			console.log(
				'onChangeStatusDataonChangeStatusData',
				this.onChangeStatusData
			)
		},
		async handleChangePackageStatus() {
			this.loading = true
			console.log(this.onChangeStatusData, 'handleChangePackageStatus')
			let [err] = await this.updatePackage({
				status: !this.onChangeStatusData.status,
				...pick(this.onChangeStatusData, [
					'id',
					'title',
					'price',
					'description',
				]),
			})

			if (err) {
				console.log(err)
				if (err.statusCode == 422) {
					this.error = err.errors
					this.$toast.error(this.$t('error-status', [this.$t('package')]))
				}
				this.loading = false
			} else {
				this.$toast.success(this.$t('success-status', [this.$t('package')]))
				this.confirmStatusDialog = false
				this.onResetUserForm()
				this.loading = false
			}
		},
		onResetUserForm() {
			this.packageForm.data = {}
			this.packageForm.dialog = false
			this.onUpdate = false
			this.onView = false
			this.error = {}
		},
	},
}
</script>
