<template>
	<v-form
		:readonly="onView && !update"
		ref="form"
		lazy-validation
		@submit.prevent="validatePackage"
	>
		<v-card
			class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6"
		>
			<div class="tw-flex tw-justify-between tw-items-start">
				<h2
					class="tw-text-22 tw-font-semibold tw-text-black tw-mb-6 lg:tw-mb-12"
				>
					{{
						onUpdate || update ? $t('updatePackage') : $t('createPackage')
					}}
				</h2>

				<div v-if="onView" class="tw-flex tw-space-s-3 tw-ms-auto">
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						tile
						color="#F2CA51"
						outlined
						@click="onUpdateFromView"
						icon
					>
						<v-icon color="tw-text-F2 ">mdi mdi-pencil</v-icon>
					</v-btn>
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						@click="onResetUserForm"
						color="#DF9999"
						dark
					>
						<v-icon color="tw-text-white">mdi mdi-close</v-icon>
					</v-btn>
				</div>
			</div>
			<div>
				<v-row>
					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('title') }}
						</div>
						<v-text-field
							outlined
							flat
							:rules="[rules.required($t('title'))]"
							class="user-input"
							hide-details="auto"
							v-model="mPackage.title"
							:placeholder="$t('enter-title')"
							dense
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="error.title ? error.title[0] : []"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('price') }}
						</div>
						<v-text-field
							outlined
							flat
							:rules="[
								rules.required($t('card-number-or-iqama')),
								rules.min(1),
							]"
							class="user-input"
							v-model="mPackage.price"
							hide-details="auto"
							placeholder="00.00"
							dense
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="error.price ? error.price[0] : []"
						/>
					</v-col>
					<v-col cols="12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('packageDescription') }}
						</div>
						<v-textarea
							:rules="[
								rules.required($t('description')),
								rules.max(255, $t('description')),
							]"
							auto-grow
							outlined
							v-model="mPackage.description"
							class="user-input"
							:placeholder="$t('write-the-description')"
							:error-messages="
								error.description ? error.description[0] : []
							"
							:counter="255"
						></v-textarea>
					</v-col>
				</v-row>
			</div>
			<v-card-actions
				v-if="!onView && !update"
				class="tw-mt-10 lg:tw-mt-14 tw-flex tw-p-0"
			>
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetUserForm"
					><span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>{{ $t('actions.cancel') }}</span
					>
				</v-btn>
				<v-btn
					type="submit"
					height="58"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					depressed
					:loading="loading"
					color="primary"
					><span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>{{ $t('actions.submit') }}</span
					>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { createFormMixin } from '@/mixins/form-mixin'
import UseFilePicker from '../../mixins/UseFilePicker'
import { mapGetters } from 'vuex'
import { pick } from 'lodash'

const initialPackage = (initialValue = {}) => ({
	price: '',
	title: null,
	description: '',
	...initialValue,
})
export default {
	name: 'package-form',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required', 'email', 'password', 'min', 'max'],
		}),
	],
	props: {
		permissions: Array,
		package: Object,
		onView: {
			type: Boolean,
			default: false,
		},
		onUpdate: {
			type: Boolean,
			default: false,
		},
		error: Object,
		loading: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		'package.id': {
			immediate: true,
			deep: true,
			handler(id) {
				console.log(id, this.package, 'watch')
				if (!id) return
				console.log(
					pick(this.package, ['id', 'title', 'price', 'description']),
					'watch1'
				)
				this.mPackage = initialPackage(
					pick(this.package, ['id', 'title', 'price', 'description'])
				)
			},
		},
	},
	data() {
		return {
			update: false,
			isLoading: false,
			mPackage: initialPackage(),
			wasViewing: false,
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
	},
	mounted() {},
	methods: {
		onResetUserForm() {
			this.$refs.form.reset()
			this.update = false
			this.wasViewing = false
			this.$emit('onClose')
		},
		validatePackage() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			if (this.onUpdate || this.update) {
				this.$root.confirmation = {
					title: this.$t('update-confirmation'),
					message: `Are you sure to update this Package?`,
					handler: () => this.$emit('update', this.mPackage),
				}
				return
			}
			this.$emit('create', this.mPackage)
		},
		onUpdateFromView() {
			this.wasViewing = true
			this.$emit('update:on-view', false)
			this.showBuyerAttachmentError = false
			this.showUserPermissionError = false
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
